import './SaleDetailList.css'
import { rounds } from '../../config/rounds';
import AnimationComponent from '../AnimationComponent';
import { useGSAP } from '@gsap/react';
import gsap, { Linear } from 'gsap';

const SaleDetailList = () => {

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.saleDetails',
                start: "top center",
            }
        });
        tl.from('.rightLiine', 0.7, { x: -100, opacity: 0, ease: 'elastic.out(1,0.3,0.8)', }, 'do')
            .from('.leftLine', 0.7, { x: 100, opacity: 0, ease: 'elastic.out(1,0.3,0.8)', }, 'do')
            .from('.title', 0.8, { y: 100, opacity: 0, ease: Linear.easeInOut, }, 'do')
            .from('.saleDetailsList', 0.8, { y: 150, opacity: 0, ease: Linear.easeInOut, }, 'do+=0.2')
    });

    return (
        <>
            <section className="saleDetails mt-10 sm:mt-0">
                <div className="oc_container relative">
                    <div className="absolute top-0 left-0 rotate-180 rightLiine">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[16vw] sm:w-[20vw] 2xl:w-[10vw] block  blinkers" />
                    </div>
                    <div className="absolute top-0 right-0 leftLine">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[16vw] sm:w-[20vw] 2xl:w-[10vw] block  blinkers" />
                    </div>
                    <div className="title">
                        <h2 className='text-2xl md:text-3xl'>Sale Details</h2>
                    </div>
                    <div className="saleDetailsList">
                        <div className='table-responsive'>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Round</th>
                                        <th>Date</th>
                                        <th>DWC Quantity</th>
                                        <th>Price  $</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rounds.map((value, idx) =>
                                            <tr>
                                                <td>{idx + 1}.</td>
                                                <td>{value.startDate}</td>
                                                <td>{value.ocAllotted.toLocaleString()}</td>
                                                <td>$ {value.price}</td>
                                                <td>15 Days / Until Completion</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default SaleDetailList