import axios from "axios";
import { useEffect, useMemo, useState } from "react";

export function ExternalApi(address) {
  const [result, setResult] = useState(false);
  const [Total, setTotal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get('https/defiworldassets/OPE789QWMLOZ.php');
        // console.log(response.data);
        // if (response.data[3]?.active === true) {
        //   setTotal([response.data[3]])
        // } else {
        //   setTotal(false)
        // }

        // // Filtering the rounds data in which active is true
        // const filteredResult = response.data.filter(item => item.address === address && item.active === true);

        // if (filteredResult.length > 0) {
        //   setResult(filteredResult)
        // }
        // else {
        //   setResult(false)
        // }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchApiConditionally = async () => {
      try {
        // const response = await axios.get('https/defiworldassets/ASD123JH8745KJ.php');

        // // Condition for to use the external api data or not 
        // if (response.data.isApiLive === true) {
        //   fetchData();
        // } else {
        //   // console.log("API is not live");
        //   setResult(false)
        // }
      } catch (error) {
        console.log(error);
      }
    };

    // Initial fetch 
    fetchApiConditionally();

    const interval = setInterval(() => {
      fetchApiConditionally();
    }, 6000);

    // Cleanup function to clear the interval 
    return () => {
      clearInterval(interval);
    };
  }, [address]);

  return useMemo(() => {
    return {
      result,
      Total
    };
  }, [result, Total]);

}