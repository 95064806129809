import './PublicSale.css'
import OCAnimOrig from '../../Assets/images/dwc-coin.gif'
import ProgressiveImage from "react-progressive-graceful-image";
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import AnimationComponent from '../AnimationComponent';
import { useAccount } from 'wagmi';
import { shortAddress } from '../../config/helpers';
import toast from 'react-hot-toast';
import { useSaleEligible } from '../../hooks/useSaleEligible';
import { url } from '../../config';
import Button_Components from '../Button_Components';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import Sale_card_frame from '../Projects/Sale_card_frame';
import { useEffect, useState } from 'react';
import { useGSAP } from '@gsap/react';
import gsap, { Linear } from 'gsap';


const PublicSale = () => {
    const { address } = useAccount();
    const [timerComplete, setTimerComplete] = useState(true);
    const id = localStorage.getItem('Active') || ''
    const { eligible } = useSaleEligible(id)
    const notify = () => toast.success('Copied Successfully!')

    const [timer, setTimer] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })

    useEffect(() => {

        const countDownDate = new Date(Date.UTC(2024, 4, 15, 11, 30, 0)).getTime();
        const x = setInterval(function () {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimer({ days, hours, minutes, seconds })
            if (distance < 0) {
                clearInterval(x);
                setTimer({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                setTimerComplete(false)
            }
        }, 1000);
        setTimerComplete(true)
        return () => clearInterval(x);
    }, [])


    let mQuery = gsap.matchMedia();

    useGSAP(() => {
        const tl = gsap.timeline({});
        tl.from('.infobox', 1.5, { y: 100, opacity: 0, ease: 'back.inOut', }, 'do')
            .from('.imageBox', 1.5, { x: -100, opacity: 0, ease: 'elastic.out(1,0.3,0.8)', }, 'do')
        mQuery.add("(min-width:768px)", () => {
            tl.from('.timmer', 1, { y: 150, opacity: 0, ease: 'back.inOut', }, 'do+=0.2')
        });

        mQuery.add("(max-width:768px)", () => {
            gsap.from('.timmer', 1, {
                y: 150, opacity: 0, scrollTrigger: {
                    trigger: '.timmer',
                    start: "top top+=300"
                }, ease: 'back.inOut',
            })
        });
    });


    return (
        <>
            <section className="publicSale">
                <div className="oc_container">
                    <div className="flex flex-col-reverse md:flex-row items-center">
                        <div className="w-full md-w-2/4 mt-5 md:mt-0">
                            <div className="infobox">
                                <h1>Gain Early Access to DWC</h1>
                                <p className="mt-4 mb-0">Join the Vanguard and Be One of the Initial Adopters of the DWC Token During DEFI World's ICO Rounds.</p>
                                <p className="mt-4 mb-0">Embrace this Chance, Drive the Change! Engage in the DEFI World ICO Rounds and Explore the World of Decentralized Finance!</p>

                                {(eligible && address) &&
                                    <div className='mt-5'>

                                        <h3 className='mt-4'>Copy Referral Link</h3>
                                        <div className="buttonGrp">
                                            {eligible && <CopyToClipboard text={`${url}?referrer=${address}`}>
                                                <button onClick={notify} className="oc_btn copyRefferalCode py-3 px-5 text-center"><span>{`${url}?referrer=${shortAddress(address)}`}</span> <CopyAllIcon className='ms-1' /></button>
                                            </CopyToClipboard>}
                                            {
                                                !eligible && <button className="oc_btn copyRefferalCode py-3 px-5 text-center">
                                                    <span>Not eligible ( min. investment of $10 reqd. )</span>
                                                </button>
                                            }
                                            <Link to="/referdetails"> <button className="oc_btn mt-3  py-3 px-5 text-center">Referral Activity Details</button></Link>
                                        </div>
                                    </div>
                                }
                                <div className='flex gap-10 sm:gap-12 mt-10 mx-auto w-full justify-center md:justify-start md:ml-8'>
                                    <a href='/#buySection1'>
                                        <Button_Components text={"ICO Details"} fun={""} />
                                    </a>
                                    <a href='#' target='blank'>
                                        <Button_Components text={"Buy DWC"} fun={""} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md-w-2/4">
                            <div className="imageBox">
                                <ProgressiveImage delay={0} src={OCAnimOrig} placeholder={OCAnimOrig}>
                                    {(src) => <img src={src} alt="DWC" className='mx-auto md:ml-auto' />}
                                </ProgressiveImage>
                            </div>
                        </div>
                    </div>
                    {/* <div className='flex justify-center relative mt-10 sm:mt-20'>
                        <div className='w-96 sm:w-[500px] md:w-[600px]  flex mx-auto relative timmer d:none'>
                            <div className="timmerBox py-4 relative h-full w-96 sm:w-[500px] md:w-[600px]">
                                <Sale_card_frame />
                                <div className="flex justify-center items-center px-9">
                                    <span className="text-xl sm:text-3xl pt-3 text-[#fff] title-defi-font tittle-shadow">
                                        {timerComplete ? "Pre-sales start in" : "Sale is started"}
                                    </span>
                                </div>
                                <p class="text-center timenum text-[#50dbff] text-xl sm:text-2xl md:text-3xl mt-12 sm:mt-11  md:mt-[6.7rem] salestatics title-defi-font">
                                    {
                                        timer.days > 0 ? `${timer.days}D` : ''
                                    }
                                    {
                                        timer.hours > 0 ? ` ${timer.hours}H` : ''
                                    }
                                    {
                                        timer.minutes > 0 ? ` ${timer.minutes}M` : ''
                                    }
                                    {
                                        timer.seconds > 0 ? ` ${timer.seconds}s` : ''
                                    }
                                </p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default PublicSale