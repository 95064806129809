import './BuySell.css'
import AnimationComponent from '../AnimationComponent';
import { useEffect, useState } from 'react';
import { formatEther } from 'viem';
import { useSaleContractData } from '../../hooks/useContractData';
import { rounds } from '../../config/rounds';
import { useLocation } from 'react-router';
import { ExternalApi } from '../../hooks/externalApi';
import { toLocale } from '../../config/helpers';
import { dwcToken, url } from '../../config';
import { addToken } from '../../hooks/addToken';



const BuySell = () => {

  const location = useLocation()
  const [progress, setProgress] = useState(0)
  const [hfgSold, setHfgSold] = useState(0)
  const [id, setid] = useState(0)

  const round = rounds.find(data => data.address === id);
  let saleCap = round?.ocAllotted;

  const { result } = useSaleContractData(id)
  const data = ExternalApi(id)

  const addMainnetToMetaMask = async () => {
    if (typeof window.ethereum === 'undefined') {
      alert('MetaMask is not installed. Please install MetaMask and try again.');
      return;
    }

    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x89', // BSC Testnet chainId in hexadecimal
            chainName: 'Polygon',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: ["https://polygon-rpc.com"],
            blockExplorerUrls: ['https://polygonscan.com/'],
          },
        ],
      });
    } catch (error) {
      console.error(error);
      alert('Error adding Polygon to MetaMask: ' + error.message);
    }
  }

  // useEffect(() => {
  //   if (data.result === false && result) {

  //     const hfRaised = result.hfSold;
  //     const totalRaised = parseFloat(formatEther(hfRaised));
  //     const progress = (totalRaised / saleCap) * 100;

  //     setProgress(progress);
  //     setHfgSold(totalRaised);
  //   } else {
  //     if (result) {
  //       setProgress(data.result[0].progress);
  //       setHfgSold(data.result[0].hfgSold);
  //     }
  //   }

  // }, [result, saleCap, data]);


  useEffect(() => {
    if (result) {
      const { hfSold } = result;
      const { result: dataResult } = data;
  
      const totalRaised = dataResult === false ? parseFloat(formatEther(hfSold)) : dataResult[0].hfgSold;
      const progress = dataResult === false ? (totalRaised / saleCap) * 100 : dataResult[0].progress;
  
      setProgress(progress);
      setHfgSold(totalRaised);
    }
  }, [result, saleCap, data]);
  


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    setid(id)
  }, [location.search])



  return (
    <section className="buySell">

      <div className="oc_container mb-28">
        <div className="row">
          <div className="col-sm-12">
            <AnimationComponent y={100} duration={1.2}>
              <div className="buySellbox relative">
                <img src='images/hardcap-frame.svg' alt='harcap-frame' className='absolute hidden xl:block top-0 left-2/4 -translate-x-2/4 w-full object-contain -z-10' />
                <div className='w-11/12 md:w-9/12	mx-auto py-4 overflow-hidden'>
                  <div className="meter w-[96%] mb-2 mx-auto">
                    <span style={{ width: saleCap == 40400 ? '40%':`${progress}%` }}>
                      <span className="animateline w-full ">
                        <span className="absolute flex text-white top-2/4 -translate-y-2/4 right-0 z-10">
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className="oc_row align-items-center space-between">
                    <p>{toLocale(hfgSold)} DWC SOLD</p>
                    <span>{toLocale(saleCap)} Hardcap</span>
                  </div>
                  <div className="buttonGrp flex flex-wrap gap-2 justify-center mt-7 w-4/5 mx-auto">
                    <button className="oc_btn py-2 px-3" onClick={() => {
                      addMainnetToMetaMask()
                    }} >

                      Add Polygon Mainnet</button>
                    <button className="oc_btn py-2 px-3" onClick={() => {
                      addToken({
                        data: {
                          address: dwcToken,
                          symbol: 'DWC',
                          decimals: 18,
                          name: "Defi World",
                          image: `${url}DeFi-Coin.png`
                        }
                      })
                    }}  >Add To Metamask</button>

                    <a href={`https://polygonscan.com/address/${id}`} rel="noreferrer" style={{ textDecoration: "none" }} className="oc_btn py-2 px-3" target="_blank">View Smart Contract </a>
                  </div>
                </div>
              </div>
            </AnimationComponent>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuySell