import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const AnimationComponent = ({ children, delay, x, y, scale, easeType, duration },) => {

    const animation = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.from(animation.current,
                {
                    duration: duration,
                    xPercent: x,
                    yPercent: y,
                    scale: scale,
                    opacity: 0,
                    ease: easeType,
                    delay: delay,
                    stagger: 0.5,
                    scrollTrigger: {
                        trigger: animation.current,
                        start: 'top center+=500',
                        // toggleActions: "play none none reverse",
                        // scrub: true,
                    }
                });
        });
        return () => ctx.revert();
    }, [animation]);
    return (
        <div ref={animation}>{children}</div>
    )
}

export default AnimationComponent