import { providers } from "ethers"
import Web3 from "web3"

export const oracleAddress = "0x2a95eA4152C2A360fb4148236dB1e2707dE64EeE"

// COIN  ADDRESS
export const dwcToken = "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F" // DWC TOKEN 
export const usdtToken = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f" // USDT TOKEN
export const usdcToken = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" // USDC TOKEN


export const graphQlUrl = "https://api.thegraph.com/subgraphs/name/whyusethis/dwc-sale-api"

export const transactionUrl = "https://polygonscan.com/tx/"

export const chainRPC = "https://special-spring-telescope.matic.quiknode.pro/a741e9d69d608d51b43edc54a5017d657dccfd85/"

export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137

// URL used in project 
export const url = "https://sale.defiworld.finance/"   // referral url
export const polygonchainRPC = "https://special-spring-telescope.matic.quiknode.pro/a741e9d69d608d51b43edc54a5017d657dccfd85/"

