import React from 'react'
import Sale_card_frame from '../Projects/Sale_card_frame'

const StatisticsBox = (props) => {
    return (
        <>
            <Sale_card_frame />
            <div className="box py-2">
                <h2 className='text-lg py-4 title-defi-font text-center w-[80%] mx-auto'>{props.tittle}</h2>
                <h3 className='mt-[42px] text-sm title--font text-center'>{props.amount}</h3>
            </div>
        </>
    )
}

export default StatisticsBox
