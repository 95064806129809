import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import Loader from '../Components/Loader/Loader'

const Layout = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);


    return (
        <>
            {
                loading === true ? <Loader /> : <>
                    <Header />
                    <div className='main'>
                        <Outlet />
                    </div>
                    <Footer />
                    <div className="help-button-container">
                        <button className="help-button" >
                            <img src="images/help-icon.svg" width={"40px"} height={"40px"} alt="Help Icon" className="help-icon" />
                            <span className="tooltip">Need Help?</span>
                        </button>
                    </div>
                </>
            }
            {/* <Header />
            <div className='main overflow-hidden'>
                <Outlet />
            </div>
            <Footer /> */}
        </>
    )
}

export default Layout