import { useLocation } from 'react-router-dom'
import { useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import './header.css'
import Button_Components from '../Button_Components'
import AnimationComponent from '../AnimationComponent'
import Header_Frame from './Header_Frame'
import { useAccount, useNetwork } from 'wagmi'
import { defaultNetwork } from '../../config'
import { shortAddress } from '../../config/helpers'



const Header = () => {

  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const { address } = useAccount()
  const { chain } = useNetwork()
  const isCorrectNetwork = chain?.id === defaultNetwork
  const location = useLocation()

  const queryParameters = new URLSearchParams(location.search)
  const refferalCode = String(queryParameters.get("referrer"))

  if (refferalCode !== "null") {
    if (address && (refferalCode.toLowerCase() === address.toLowerCase())) {
      localStorage.removeItem('referrer')
    } else {
      localStorage.setItem("referrer", refferalCode)

    }
  }

  return (
    <>
      <header className='w-full h-52 relative overflow-hidden bg-black'>
        <AnimationComponent y={-200} duration={1.2} delay={1}>
          <div className='w-full h-52 relative py-4'>
            <Header_Frame />
            <div className='container px-4 md:px-10 lg:px-3 xl:px-0 mx-auto relative z-10'>
              <div className='w-full lg:w-4/5	 mx-auto py-2'>
                <nav className='w-full relative flex justify-between items-center z-20'>
                  <AnimationComponent scale={0} duration={1} delay={1}>
                    <div className='logo-brand'>
                      <img src='images/dwc-coin.gif' alt='logo-brand-icon' width={'45px'} height={'48px'} className='h-14 w-14 block' />
                    </div>
                  </AnimationComponent>
                  <ul className='flex gap-3 mr-2 sm:ml-0'>
                    <li>
                      {openConnectModal && (
                        <AnimationComponent scale={0} duration={1} delay={1}>
                          <Button_Components fun={openConnectModal} text={"Connect Wallet"} />
                        </AnimationComponent>
                      )}
                      {openAccountModal && (
                        <AnimationComponent scale={0} duration={1} delay={1}>
                          <Button_Components fun={openAccountModal} text={shortAddress(address)} />
                        </AnimationComponent>
                      )}
                      {isCorrectNetwork ? "" :
                        <>
                          {openChainModal && (
                            <AnimationComponent scale={0} duration={1} delay={1}>
                              <Button_Components fun={openChainModal} text={"Wrong network"} />
                            </AnimationComponent>
                          )}
                        </>
                      }
                    </li>
                  </ul>
                  <div className="logoDesktop z-[10]">
                    <AnimationComponent y={'-200'} duration={1} delay={1.5} opacity={0}>
                      <ul className='xy'>
                        <ul className='flex items-center'>
                          <li>D</li>
                          <li>e</li>
                          <li>f</li>
                          <li>i</li>
                        </ul>
                        <ul className='flex items-center'>
                          <li>W</li>
                          <li>o</li>
                          <li>r</li>
                          <li>l</li>
                          <li>d</li>
                        </ul>
                      </ul>

                    </AnimationComponent>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </AnimationComponent>
      </header>

    </>
  )
}

export default Header