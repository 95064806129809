import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";
import AnimationComponent from '../AnimationComponent';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const data = {
    rows: [
        {
            title: "Which wallet should I use?",
            content: `Defi World works with all crypto wallets. We recommend using Trust Wallet or Metamask Wallet.`,
        },
        {
            title: "When can I Buy DWC ?",
            content: `DEFI World Sale starts sharp on 15/05/2024`,
        },
        {
            title: "When can I Claim my DWC?",
            content: `If you have purchased DWC token during sale, You may claim it on after 2 Months`,
        },
        {
            title: "I can't Buy DWC",
            content: `Please make sure that you are connected to Polygon Chain via Metamask or Trust wallet or any wallet that supports Polygon Chain & you have enough Matic to pay for gas fee.`,
        },
        {
            title: "Will I Get referral on referring a user to buy DWC?",
            content: `You will receive a referral when a user uses your referral link & buys DWC in any of the 3 sale round.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#50dbff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#50dbff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};


const Faqs = () => {

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.faqs',
                start: "top center"
            }
        });
        tl.from('.faqRight', 2, { x: 100, opacity: 0, ease: "bounce.inOut" }, 'with')
            .from('.faqLeft', 2, { x: -100, opacity: 0, ease: "bounce.inOut" }, 'with')
            .from('.faqTitle', 2, { y: 100, opacity: 0, stagger: 0.05, ease: "back.inOut" }, 'with+=0.01')
            .from('.faq-row', 2, { y: 100, opacity: 0, ease: "back.inOut", stagger: "0.06" }, 'with+=0.2')
    });

    return (
        <section className="faqs">
            <div className="oc_container">
                <div className='titlebox mx-auto text-center'>
                    <div className="absolute top-0 sm:top-[10px] lg:top-0 right-0 faqRight">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                    <div className="absolute top-0 sm:top-[10px] lg:top-0 left-0 rotate-180 faqLeft">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                    <h6 className='text-[#50dbff] mb-1 md:mb-0 faqTitle'>FAQ's</h6>
                    <h2 className='text-3xl md:text-5xl mb-2 faqTitle'>Quick Questions</h2>
                    <p className='mb-5 faqTitle'>Here are Some Important things you should know!.</p>
                </div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs