import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const useAuthAccount = () => {
    const location = useLocation();
    const { address: connectedAddress } = useAccount()
    const [address, setAddress] = useState(null)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setAddress(queryParams.get('user') || connectedAddress);
    }, [location.search, connectedAddress]);

    return { address }
}