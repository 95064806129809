import './Projects.css'
import { useNavigate } from "react-router-dom"
import Slale_Card_Frame from "./Slale_Card_Frame"
import AnimationComponent from "../AnimationComponent"
import { useState } from 'react';
import { useSaleContractData } from '../../hooks/useContractData';
import { ExternalApi } from '../../hooks/externalApi';
import { useEffect } from 'react';
import { formatEther, formatUnits } from 'viem';
import { toLocale } from '../../config/helpers';
import Countdown from "react-countdown";
import { useCallback } from 'react';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <span>You missed it!</span>;
    } else {
        // Render a countdown
        return <span>{days}d:{hours}h:{minutes}m:{seconds}s</span>;
    }
};

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
function processSaleData(data, saleCap, startTime) {
    const saleStart = parseFloat(data.started);

    const hfRaised = data.hfSold;
    const endDate = ((saleStart + (15 * 86400)) * 1000);
    const totalRaised = parseFloat(formatEther(hfRaised));
    const progress = (totalRaised / saleCap) * 100;

    return {
        status: data.state,
        hfgSold: totalRaised,
        usdcRaised: parseFloat(formatUnits(data.usdcRaised, 6)),
        usdtRaised: parseFloat(formatUnits(data.usdtRaised, 6)),
        progress,
        countdown: data.state === 1 ? endDate : startTime
    };
}


export const RoundPage = ({ address, startTime, idx, ocAllotted, price }) => {
    const saleCap = ocAllotted;

    const navigate = useNavigate()

    const [saleData, setSaleData] = useState({
        hfgSold: 0,
        usdcRaised: 0,
        usdtRaised: 0,
        progress: 0,
        countdown: 0,
        status: 0,
    });

    const { result: data } = useSaleContractData(address);

    const updateSaleData = useCallback((newData) => {
        setSaleData(prevData => ({ ...prevData, ...newData }));
    }, []);


    useEffect(() => {
        if (data) {
            updateSaleData(processSaleData(data, saleCap, startTime));
        }

    }, [data, startTime, updateSaleData, saleCap]);

    const navigateToSaleDetails = () => {
        navigate(`/saledetails?id=${address}`);
    };

    return (
        <div id={`buySection${idx}`}>
            <div className={`sale-card ${saleData.status === 1 ? "active_Class" : ""} ${saleData.status === 2 ? " opacity-70" : ""} w-[370px] h-[410px] lg:w-[422px] lg:h-[464px] xl:w-[385px] xl:h-[425px] 2xl:w-[415px] 2xl:h-[480px] flex flex-col justify-center items-center`}>
                <Slale_Card_Frame />
                <div className={`listBoxInner flex flex-col justify-center h-full active w-full`}>
                    <div className="row profileInfo absolute top-1 sm:top-0 left-2/4 -translate-x-2/4 w-full">
                        <div className="w-full flex justify-between sm:px-0 items-center sm:mt-1">
                            <div className="logo-brand pl-12">
                                <img className="profilePic" src={'images/DeFi-Coin.png'} alt="" width="56" height="56" />
                            </div>
                            <h2 className="pr-12 title-defi-font tittle-shadow">Round {idx}</h2>
                        </div>
                        <ul className="mx-auto w-full flex justify-center mt-0 lg:mt-2 md:px-5">
                            <li className={`upcoming ${(address) && (saleData.status === 0) && "active"}`} >Upcoming</li>
                            <li className={`saleLive ${(address) && (saleData.status === 1) && 'active'} `}>Sale Live</li>
                            <li className={`saleEnded ${((address) && (saleData.status === 2) && 'active')}`}>Sale Ended</li>
                        </ul>
                    </div>
                    <div className="infoBox z-20 px-5 sm:px-10 md:px-5 overflow-hideen mt-9">
                        <p className="subtitle sm:mb-4 mb-1">Fair Launch</p>
                        <h3 className="sm:mt-3">Hardcap</h3>
                        <strong> {ocAllotted} DWC</strong>
                        <p className="progressBar">Progress <span className="text-[#50dbff]">{idx == 2 ? 47 : toLocale(saleData.progress)}%</span></p>
                        <div className="meter w-[97%] mt-2">
                            <span style={{ width: idx == 2 ? `${toLocale(47)}%` : `${toLocale(saleData.progress)}%` }}>
                                <span className={`animateline ${saleData.status === 2 && "test"} w-full`}>
                                    <span className="absolute flex text-white top-2/4 -translate-y-2/4 right-0 z-10">
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="row tableBox">
                            <div className="flex justify-between mt-3">
                                <p className="text-[#fff]">
                                    <span className="flex text-[#50dbff] mr-1">{idx == 2 ? '16,160' : toLocale(saleData.hfgSold)}</span>
                                    DWC
                                </p>
                                <p className="text-[#fff]">
                                    <span className="flex text-[#50dbff] mr-1">{saleCap.toLocaleString()}</span>
                                    DWC
                                </p>
                            </div>
                            <p className='mt-2'>DWC Price: <span className="text-[#50dbff]"> ${price}</span></p>
                            <p className='mt-2'>DWC Sold: <span className="text-[#50dbff]">{idx == 2 ? '18,988' : toLocale(saleData.hfgSold)} DWC</span></p>
                        </div>
                        <div className="dividerLine my-3"></div>
                        <div className="row saleTineView mt-2 sm:mt-3 2xl:mt-4 flex">
                            {(saleData.status === 2) && <p className="timesSale text-[#50dbff] font-bold">
                                Sale Ended
                            </p>}
                            {/* {(saleData.status === 1) && <p className="timesSale text-[#50dbff] font-bold">
                                Sale Ends In:
                                <br />
                                <Countdown date={saleData.countdown} renderer={renderer} />
                            </p>} */}

                            {(saleData.status === 0) && <p className="timesSale text-[#50dbff] font-bold">
                                Sale Starts In:
                                <br />
                                <Countdown date={startTime} renderer={renderer} />
                            </p>}

                            <button className="btnView" style={{ border: 'none' }}
                                disabled={saleData.status === 0 && true}
                                onClick={() => {
                                    navigateToSaleDetails();
                                    scrollToTop();
                                }}>
                                View
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}