import React, { useCallback, useEffect, useState } from 'react'
import './RefDetails.css'
import Tooltip from "react-simple-tooltip"
import { css } from "styled-components"
import { shortAddress, toLocale } from '../../config/helpers';
import { useGraphData } from '../../hooks/useGraph';
import { Pagination } from '@mui/material';
import AnimationComponent from '../../Components/AnimationComponent';
import StatisticsBox from '../../Components/Statisics/StaticsBox';
import { formatUnits } from 'viem';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyAllIcon from '@mui/icons-material/CopyAll';



const Ref = () => {

    const { data, totalBonus } = useGraphData()

    const [pagination, setPagination] = useState([]);

    const pageHandler = useCallback(() => {
        const PageCount = Math.ceil(Number(data.length) / 10)
        setPagination(PageCount)
    }, [data])


    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }

    useEffect(() => {
        pageHandler()
    }, [pageHandler])


    return (
        <>
            <section className="refDetails">
                <div className="oc_container">
                    <div className="title">
                        <AnimationComponent y={'100'} duration={1} delay={0.2}>
                            <h2 className='mb-5 text-4xl md:text-5xl'>Referral Bonus</h2>
                        </AnimationComponent>
                    </div>
                    <div className="projects">
                        <div className="oc_container">
                            {/* List Box */}
                            <div className="row mb-5">
                                <div className="flex flex-wrap gap-y-5 gap-x-5 justify-center">
                                    <div className="w-80">
                                        <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                            <StatisticsBox
                                                tittle={"Referral claimed"}
                                                amount={0}
                                            />
                                        </AnimationComponent>
                                    </div>
                                    <div className="w-80">
                                        <AnimationComponent y={'100'} duration={1} delay={0.3} scale={0}>
                                            <StatisticsBox
                                                tittle={'No. of referrals'}
                                                amount={data.length}
                                            />
                                        </AnimationComponent>
                                    </div>
                                    <div className="w-80">
                                        <AnimationComponent y={'100'} duration={1} delay={0.4} scale={0}>
                                            <StatisticsBox
                                                tittle={'Total Referral Bonus'}
                                                amount={<p className='-m-4'>
                                                    {totalBonus || 0}
                                                </p>}
                                            />
                                        </AnimationComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AnimationComponent y={'100'} duration={1} delay={0.8}>
                        <div className="refDetailsList">
                            <div className='table-responsive h-[413px] overflow-y-auto'>
                                <table width="100%" height="100%">
                                    <thead>
                                        <tr>
                                            <th>Sr.no</th>
                                            <th>Wallet Address</th>
                                            <th>DWC Buy</th>
                                            <th>Bonus Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.length > 0 ? data.slice(indexOfFirst, indexOfLast).map((pack, i) => (
                                            <tr key={i}>
                                                <td>{i + indexOfFirst + 1}</td>
                                                <td>
                                                    <Tooltip content={pack.referredUser} customCss={css` white-space: nowrap;`}>
                                                        <CopyToClipboard text={pack.referredUser}>
                                                            <span>{shortAddress(pack.referredUser)}<CopyAllIcon className='ms-1' fontSize='small' /></span>
                                                        </CopyToClipboard>
                                                    </Tooltip>
                                                </td>

                                                <td>{toLocale(formatUnits(pack?.amount, 18))} DWC</td>
                                                <td>{toLocale(formatUnits(pack?.hfgBonus, 18))} DWC</td>
                                            </tr>
                                        )) : <>
                                            <tr>
                                                <td colSpan={4}><p className='text-center m-4'>No Data Found</p></td>
                                            </tr>
                                        </>}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </AnimationComponent>
                </div>
                {data.length === 0 ?
                    ''
                    : <>
                        {/* {pagination <= 1 ? '' : <div > */}
                        <Pagination className='myPagination' onChange={pagechange} count={pagination} />
                        {/* </div>} */}
                    </>
                }
            </section>
            {/* <section className="claimBonus">
                <AnimationComponent y={'100'} duration={1} delay={0.2}>
                    <div className="oc_container mx-auto text-center">
                        <div className="flex justify-center mx-auto text-center">
                            <button className="oc_btn py-3 px-5 text-center">Claim Bonus</button>
                            <Button_Components text={'Claim Bonus'} fun={""}/>
                            <br />
                            {TimeStatus ? <p>Next Claim after <Countdown date={Timer} /></p> : false}
                        </div>

                        <br />
                        <br />
                        <p>You can claim after 60 days, when sale ends</p>
                        <p>You will be claiming 10% of your referral earnings in an interval of 30 days, total 10 claims.</p>
                    </div>
                </AnimationComponent>
            </section> */}


        </>

    )
}

export default Ref
