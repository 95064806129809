import './Projects.css'
import { rounds } from "../../config/rounds";
import { RoundPage } from "./RoundPage";
import AnimationComponent from "../AnimationComponent";
import SaleCard from "./SaleCard";
import { useGraphData } from '../../hooks/useGraph';
import { useGSAP } from '@gsap/react';
import gsap, { Linear } from 'gsap';

const Projects = () => {

    const { hfgSold, usdRaised } = useGraphData();

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: { trigger: '.projects', start: "top center" }
        });

        tl.from('.p-rightLiine', 0.7, { x: -100, opacity: 0, ease: 'elastic.out(1,0.3,0.8)', }, 'do')
            .from('.p-leftLine', 0.7, { x: 100, opacity: 0, ease: 'elastic.out(1,0.3,0.8)' }, 'do')
            .from('.text', 0.8, { y: 100, opacity: 0, ease: Linear.easeInOut, }, 'do')
            .from('.countinbox', 2, { y: 150, opacity: 0, ease: 'back.inOut', stagger: 0.1 }, 'do+=0.2')
            .from('.sale-card', 2, { y: 150, opacity: 0, ease: 'back.inOut', stagger: 0.09 }, 'do+=0.4')
    })

    return (
        <section className="projects">
            <div className="oc_container">
                <div className="relative mb-10 overflow-hidden">
                    <div className="absolute top-3 lg:top-0 -right-8 sm:right-0 p-rightLiine">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                    <div className="absolute top-3 lg:top-0 -left-8 sm:left-0 rotate-180 p-leftLine">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                    <div className="infoBox text-center mb-5">
                        <h2 className='text-4xl	md:text-5xl text'>Sale Statistics</h2>
                        <p className='text'>Overall statistics for DWC Sale</p>
                    </div>
                </div>
                <div className="row mb-10 lg:mb-14">
                    <div className="col-md-12 col-lg-12">
                        <div className="flex flex-wrap gap-y-5 gap-x-5 justify-center countBox">
                            <div className="w-80 lg:w-96 relative">
                                <SaleCard
                                    tittle={'Total DWC Sold'}
                                    img={"images/dwc-sold.webp"}
                                    img_width={"36px"}
                                    img_height={"36px"}
                                    img_alt={"dwc-sold"}
                                    amount={hfgSold}
                                />
                            </div>
                            <div className="w-80 lg:w-96 relative">
                                <SaleCard
                                    tittle={'Total USD Raised'}
                                    img={"images/usd-raised.webp"}
                                    img_width={"44px"}
                                    img_height={"36px"}
                                    img_alt={"usd-raised"}
                                    amount={usdRaised}
                                />
                            </div>
                            <div className="w-80 lg:w-96 relative">
                                <SaleCard
                                    tittle={'Round Left'}
                                    img={"images/round-left.webp"}
                                    img_width={"84px"}
                                    img_height={"36px"}
                                    img_alt={"round-left"}
                                    amount={"4"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-center md:justify-between gap-y-5 listBox mb-14 lg:mb-20 2xl:gap-x-3">
                    {
                        rounds.map((value, idx) => {
                            return (
                                <RoundPage
                                    address={value.address}
                                    price={value.price}
                                    ocAllotted={value.ocAllotted}
                                    startTime={value.startTime}
                                    idx={idx + 1}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Projects