import './Statisics.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortAddress, toLocale } from '../../config/helpers';
import AnimationComponent from '../AnimationComponent';
import StatisticsBox from './StaticsBox';
import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';
import { useSaleContractData } from '../../hooks/useContractData';
import { ExternalApi } from '../../hooks/externalApi';
import { rounds } from '../../config/rounds';
import { formatEther, formatUnits } from 'viem';
import { url } from '../../config';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Statisics = () => {
    const { address } = useAccount()
    const [id, setid] = useState(0)
    const location = useLocation()
    const [hfgSold, setHfgSold] = useState(0);
    const [usdRaised, setUsdRaised] = useState(0);
    const [usdcRaised, setUsdcRaised] = useState(0);
    const [usdtRaised, setUsdtRaised] = useState(0);

    const salePrice = rounds.filter(data => data.address === id)[0]?.price;
    const saleSupply = rounds.filter(data => data.address === id)[0]?.ocAllotted;

    const { result: data, isLoading } = useSaleContractData(id)
    const { result } = ExternalApi(id)


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        setid(id)
    }, [location.search])


    useEffect(() => {
        if (!isLoading) {
            // if (result === false) {
            if (data) {
                setUsdRaised(formatUnits(data.totalRaised, 6))
                const sale = data
                const hfRaised = sale.hfSold
                const totalRaised = parseFloat(formatEther(hfRaised))
                setHfgSold(totalRaised)
                setUsdcRaised(parseFloat(formatUnits(sale.usdcRaised, 6)))
                setUsdtRaised(parseFloat(formatUnits(sale.usdtRaised, 6)))
            }
            // } else {
            //     setUsdRaised(result[0].usdcRaised + result[0].usdtRaised)
            //     setHfgSold(result[0].hfgSold);
            //     setUsdcRaised(result[0].usdcRaised);
            //     setUsdtRaised(result[0].usdtRaised);

            // }

        }
    }, [data, setHfgSold, setUsdRaised, isLoading])

    const notify = () => toast.success('Copied Successfully!');

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".statisics",
                start: "top center"
            }
        });

        tl.from('.infoTitle', 1, { y: 100, opacity: 0, ease: "back.inOut" }, 'yy')
            .from('.staticsBox', 1, { y: 100, scale: 0, opacity: 0, stagger: 0.2, ease: "back.inOut" }, 'yy')
    });

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".saleStatics",
                start: "top center"
            }
        });

        tl.from('.salTitle', 1, { y: 100, opacity: 0, ease: "back.inOut" }, 'xx')
            .from('.salStaticsBox', 1, { y: 100, scale: 0, opacity: 0, stagger: 0.2, ease: "back.inOut" }, 'xx+=0.05')
    })


    return (
        <>
            <section className="statisics">
                <div className="oc_container">
                    <div className="row">
                        <div className="col-12">
                            <div className="boxinner leftinfo">
                                <h2 className='text-center text-2xl infoTitle'>STATISTICS</h2>
                                <div className="flex flex-wrap gap-y-5 gap-x-5 justify-center mt-3">
                                    <div className="w-80 relative staticsBox">
                                        <StatisticsBox
                                            tittle={"Total Supply"}
                                            amount={"10,000,000"}
                                        />
                                    </div>
                                    <div className="w-80 relative staticsBox">
                                        <StatisticsBox
                                            tittle={"Sale Supply"}
                                            amount={toLocale(saleSupply)}
                                        />
                                    </div>
                                    <div className="w-80 relative staticsBox">
                                        <StatisticsBox
                                            tittle={"Vesting Period"}
                                            amount={"After 2 months"}
                                        />
                                    </div>
                                    <div className="w-80 relative staticsBox">
                                        <StatisticsBox
                                            tittle={"Referral"}
                                            amount={"upto 5%"}
                                        />
                                    </div>
                                    <div className="w-80 relative staticsBox">
                                        {address &&
                                            <StatisticsBox
                                                tittle={'Copy Referral Link'}
                                                amount={<CopyToClipboard text={`${url}?referrer=${address}`}>
                                                    <h4 onClick={notify} className='copyReferralLink text-sm -mt-[25px]' >{`${url}?referrer=${shortAddress(address)}`}</h4>
                                                </CopyToClipboard>}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-12 saleStatics">
                            <div className="boxinner rightinfo">
                                <h2 className='text-center text-2xl mb-3 salTitle'>SALE STATISTICS</h2>
                                <div className="flex flex-wrap gap-y-5 gap-x-5 justify-center">
                                    <div className="w-80 relative salStaticsBox">
                                        <StatisticsBox
                                            tittle={"DWC Price"}
                                            amount={salePrice}
                                        />
                                    </div>
                                    <div className="w-80 relative salStaticsBox">
                                        <StatisticsBox
                                            tittle={"Total Raised"}
                                            amount={saleSupply == 40400 ? '25,048':usdRaised}
                                        />
                                    </div>
                                    <div className="w-80 relative salStaticsBox">
                                        <StatisticsBox
                                            tittle={"Total USDC"}
                                            amount={usdcRaised}
                                        />
                                    </div>
                                    <div className="w-80 relative salStaticsBox">
                                        <StatisticsBox
                                            tittle={"Total USDT"}
                                            amount={saleSupply == 40400 ? '25,048':usdtRaised}
                                        />
                                    </div>
                                    <div className="w-80 relative salStaticsBox">
                                        <StatisticsBox
                                            tittle={"Total DWC Sold"}
                                            amount={saleSupply == 40400 ? '16,160':hfgSold}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}

export default Statisics