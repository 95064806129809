import Countdown from "react-countdown";
import './BalanceOverview.css'
import AnimationComponent from '../AnimationComponent';
import { toLocale } from '../../config/helpers';
import { useSaleClaim, useSaleUserData } from "../../hooks/useContractData";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { formatUnits } from "viem";
import { formatEther } from "ethers/lib/utils";


const BalanceOverview = () => {
    const [id, setId] = useState(0);
    const { userInfo, dwcBalance, userClaimed } = useSaleUserData(id);
    const location = useLocation();
    const { execute } = useSaleClaim(id)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setId(queryParams.get('id') || 0);
    }, [location.search]);

    const formattedUserInfo = useMemo(() => {
        if (!userInfo) {
            return null;
        }

        console.debug(userClaimed)

        return {
            usdcSpent: toLocale(formatUnits(userInfo.usdcSpent, 6)),
            usdtSpent: toLocale(formatUnits(userInfo.usdtSpent, 6)),
            totalSpent: toLocale(formatUnits(userInfo.totalSpent, 6)),
            hfBought: toLocale(formatUnits(userInfo.hfBought, 18)),
            dwcClaimed: parseFloat(formatEther(userClaimed?.hfClaimed ?? "0")),
            dwcBought: parseFloat(formatEther(userInfo.hfBought)),
        };
    }, [userInfo]);

    if (!formattedUserInfo) {
        return (
            <section className="balanceOverview">
                <div className="oc_container">
                    <p>Loading data or no data available...</p>
                </div>
            </section>
        );
    }

    

    const handleClaim = async () => {
        const txn = await execute()
        if (txn) {
            await txn.wait()
        }
    }

    return (
        <section className="balanceOverview">

            <div className="oc_container relative">
                <AnimationComponent x={"100"} duration={1.2} scale={0}>
                    <div className="absolute top-3 lg:top-0 -right-8 sm:right-0">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[12vw] lg:w-[15vw] 2xl:w-[15vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                </AnimationComponent>
                <AnimationComponent x={"-100"} duration={1.2} scale={0}>
                    <div className="absolute top-3 lg:top-0 -left-8 sm:left-0 rotate-180">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[12vw] lg:w-[15vw] 2xl:w-[15vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                </AnimationComponent>
                <AnimationComponent y={"100"} duration={1.2} >
                    <div className="title mb-10">
                        <h2 className='text-4xl	md:text-5xl'>DWC Balance Overview</h2>
                    </div>
                </AnimationComponent>
                <AnimationComponent y={"100"} duration={1.2} scale={0} delay={0.2}>
                    <div className="balanceOverviewBox">
                        <ul>
                            <li><p>Your DWC Balance </p><strong> {toLocale(dwcBalance)} DWC</strong></li>
                            <li><p>USDC Invested </p><strong>{formattedUserInfo.usdcSpent} USDC </strong></li>
                            <li><p>USDT Invested </p><strong>{formattedUserInfo.usdtSpent} USDT</strong></li>
                            <li><p>Investment in USD </p><strong>${formattedUserInfo.totalSpent}</strong></li>
                            <li><p>DWC Bought </p><strong>{formattedUserInfo.hfBought} DWC</strong></li>
                            <li><p>DWC Claimed </p><strong>{formattedUserInfo.dwcClaimed} DWC</strong></li>
                            {
                                formattedUserInfo.dwcClaimed === 0 && formattedUserInfo.dwcBought > 0 &&
                                <li>
                                    <p>Claim </p><strong className='countDown'>

                                    You can claim now

                                </strong></li>
                            }
                        </ul>
                        { formattedUserInfo.dwcBought > 0 && formattedUserInfo.dwcClaimed === 0 &&
                            <div className="buttonGrp">
                                <button className="oc_btn py-2 px-3 mx-auto flex text-center justify-center" onClick={handleClaim}>{"Claim"}</button>
                            </div>
                        }
                    </div>
                </AnimationComponent>
            </div>
        </section>
    )
}

export default BalanceOverview