export const rounds = [
    {
        address: "0xb7cd8f0f3d22c28216e3dbe4c9542578e0ced748",
        price: 1.25,
        startDate: "15 May 2024",
        ocAllotted: 21800,
        startTime: 1715772600000
    },
    {
        address: "0xbbE0e49ef2CcDe0bd33378a77B99403B34A7001A",
        price: 1.55,
        startDate: "30 May 2024",
        ocAllotted: 40400,
        startTime: 1717068600000
    },
    {
        address: "0xCDA32Af5c36FDA569598E579bBA4D513ef467Cef",
        price: 1.95,
        startDate: "15 June 2024",
        ocAllotted: 80800,
        startTime: 1718451000000
    },
    {
        address: "0xBeD4f583c248cb81DfBa1963451aFEa310559962",
        price: 2.35,
        startDate: "30 June 2024",
        ocAllotted: 161600,
        startTime: 1719747000000
    },

]



