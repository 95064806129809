import React from 'react'

const Slale_Card_Frame = () => {
    return (
        <div className="absolute center-position w-full h-full -z-20">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={'100%'}
                height={'100%'}
                viewBox="0 0 425 492"
                fill="none"
                className='w-full h-full'
            >
                <path
                    d="M425 481.281H0V12.4285H425V481.281ZM3.62612 473.379H421.369V20.331H3.62612V473.379Z"
                    fill="#10344C"
                />
                <path
                    d="M11.3652 1L44.1098 59.2084H123.317L148.879 85.4644H276.233L301.795 59.2061H381.002L413.747 1H11.3652Z"
                    fill="url(#paint0_linear_184_151)"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M287.645 73.973H346.113L357.921 62.3124H410.927L424.843 48.6224"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M349.458 70.3777H352.428L360.542 62.3345H357.597L349.458 70.3777Z"
                    fill="#01A1FE"
                />
                <path
                    d="M406.468 63.4386L417.657 52.3512H421.593L410.407 63.4386H406.468Z"
                    fill="#01A1FE"
                />
                <path
                    d="M403.708 16.3594H408.373L412.526 12.4594"
                    stroke="#01A1FE"
                    strokeWidth="0.25"
                    strokeLinejoin="round"
                />
                <path
                    d="M137.468 74.1677H137.663V73.7777H137.468V74.1677ZM78.9156 73.9727L78.7783 74.1112C78.8148 74.1474 78.8642 74.1677 78.9156 74.1677V73.9727ZM67.1514 62.311L67.2887 62.1725C67.2521 62.1363 67.2028 62.116 67.1514 62.116V62.311ZM14.3373 62.311L14.1992 62.4486C14.2358 62.4853 14.2855 62.506 14.3373 62.506V62.311ZM0.357194 47.9052C0.282176 47.8279 0.158723 47.8261 0.0814529 47.9011C0.00418316 47.9761 0.00235784 48.0996 0.0773759 48.1768L0.357194 47.9052ZM137.468 73.7777H78.9156V74.1677H137.468V73.7777ZM79.0529 73.8342L67.2887 62.1725L67.0141 62.4495L78.7783 74.1112L79.0529 73.8342ZM67.1514 62.116H14.3373V62.506H67.1514V62.116ZM14.3373 62.311C14.4755 62.1734 14.4755 62.1733 14.4754 62.1733C14.4753 62.1732 14.4752 62.1731 14.475 62.1729C14.4747 62.1726 14.4742 62.1721 14.4736 62.1715C14.4723 62.1702 14.4704 62.1683 14.4679 62.1657C14.4628 62.1606 14.4552 62.153 14.4452 62.143C14.4252 62.1229 14.3954 62.093 14.3564 62.0538C14.2785 61.9755 14.1636 61.8602 14.0162 61.7121C13.7214 61.4159 13.2962 60.9886 12.7747 60.4644C11.7318 59.4158 10.3038 57.9791 8.76345 56.4268C5.68249 53.3219 2.15327 49.7552 0.357194 47.9052L0.0773759 48.1768C1.87522 50.0287 5.40603 53.597 8.48661 56.7015C10.0271 58.254 11.4552 59.6907 12.4982 60.7394C13.0197 61.2637 13.445 61.691 13.7398 61.9872C13.8872 62.1353 14.0021 62.2507 14.0801 62.329C14.1191 62.3682 14.1488 62.3981 14.1689 62.4182C14.1789 62.4282 14.1864 62.4358 14.1915 62.4409C14.1941 62.4435 14.196 62.4454 14.1973 62.4467C14.1979 62.4473 14.1984 62.4478 14.1987 62.4481C14.1989 62.4483 14.199 62.4484 14.1991 62.4485C14.1991 62.4486 14.1992 62.4486 14.3373 62.311Z"
                    fill="#50DBFF"
                />
                <path
                    d="M75.2889 70.3784H72.3175L64.1953 62.3374H67.1496L75.2889 70.3784Z"
                    fill="#01A1FE"
                />
                <path
                    d="M18.2827 63.4386L7.08594 52.3512H3.15039L14.3357 63.4386H18.2827Z"
                    fill="#01A1FE"
                />
                <path
                    d="M21.0439 16.3595H16.3781L11.0073 11.6588"
                    stroke="#01A1FE"
                    strokeWidth="0.25"
                    strokeLinejoin="round"
                />
                <path
                    d="M59.1445 491.19L84.083 466.462H144.407L163.875 447.174H260.874L280.342 466.462H340.663L365.601 491.19H59.1445Z"
                    fill="url(#paint1_linear_184_151)"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='light-animation'
                    style={{ mixBlendMode: "screen" }}
                    d="M330.061 461.05C330.061 463.849 327.752 466.117 324.902 466.117C322.053 466.117 319.744 463.849 319.744 461.05C319.744 458.25 322.053 455.982 324.902 455.982C327.752 455.982 330.061 458.25 330.061 461.05Z"
                    fill="url(#paint2_radial_184_151)"
                />
                <path
                    className='light-animation'
                    style={{ mixBlendMode: "screen" }}
                    d="M317.451 461.05C317.451 463.849 315.142 466.117 312.292 466.117C309.442 466.117 307.133 463.849 307.133 461.05C307.133 458.25 309.442 455.982 312.292 455.982C315.142 455.982 317.451 458.25 317.451 461.05Z"
                    fill="url(#paint3_radial_184_151)"
                />
                <path
                    className='light-animation'
                    style={{ mixBlendMode: "screen" }}
                    d="M305.987 461.05C305.987 463.849 303.421 466.117 300.255 466.117C297.088 466.117 294.523 463.849 294.523 461.05C294.523 458.25 297.088 455.982 300.255 455.982C303.421 455.982 305.987 458.25 305.987 461.05Z"
                    fill="url(#paint4_radial_184_151)"
                />
                <path
                    d="M268.666 454.968H346.112L357.92 466.629H410.926L424.842 480.319"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M349.457 458.561H352.427L360.541 466.604H357.596L349.457 458.561Z"
                    fill="#01A1FE"
                />
                <path
                    d="M406.468 466.629L417.662 477.716H421.597L410.412 466.629H406.468Z"
                    fill="#01A1FE"
                />
                <path
                    d="M372.279 482.206L363.211 473.224H400.354L409.417 482.206H372.279Z"
                    fill="black"
                    stroke="#01A1FE"
                    strokeWidth="0.25"
                    strokeLinejoin="round"
                />
                <path
                    d="M403.706 476.543H408.372L412.524 480.443"
                    stroke="#01A1FE"
                    strokeWidth="0.25"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M391.532 474.348L392.564 475.379L390.441 477.484L392.572 479.589L391.531 480.621L388.367 477.485L391.532 474.348Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M387.336 474.347L388.368 475.378L386.246 477.477L388.369 479.582L387.337 480.605L384.181 477.477L387.336 474.347Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M383.14 474.348L384.181 475.379L382.05 477.484L384.181 479.589L383.14 480.621L379.985 477.485L383.14 474.348Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M156.081 454.967H78.9156L67.1514 466.633H14.3373C14.3373 466.633 3.81121 477.197 0.217285 480.899"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M75.2894 458.561H72.318L64.1958 466.602H67.15L75.2894 458.561Z"
                    fill="#01A1FE"
                />
                <path
                    d="M18.2832 466.629L7.08986 477.716H3.15088L14.3362 466.629H18.2832Z"
                    fill="#01A1FE"
                />
                <path
                    d="M52.4755 482.206L61.5388 473.224H24.3821L15.3257 482.206H52.4755Z"
                    fill="black"
                    stroke="#01A1FE"
                    strokeWidth="0.25"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.0439 476.549H16.3781L11.0073 481.256"
                    stroke="#01A1FE"
                    strokeWidth="0.25"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion-svg'
                    d="M93.4112 62.6465L92 64.0451L94.8784 66.8987L92 69.7509L93.4112 71.1494L97.6884 66.8987L93.4112 62.6465Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion-svg'
                    d="M99.1017 62.657L97.6904 64.0434L100.569 66.897L97.6904 69.7491L99.0892 71.1477L103.379 66.897L99.1017 62.657Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion-svg'
                    d="M104.789 62.657L103.377 64.0434L106.256 66.897L103.377 69.7491L104.789 71.1477L109.066 66.897L104.789 62.657Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M329.655 71.1494L331.066 69.7508L328.188 66.8972L331.066 64.045L329.655 62.6465L325.377 66.8972L329.655 71.1494Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M323.964 71.139L325.375 69.7527L322.497 66.899L325.375 64.0469L323.977 62.6483L319.687 66.899L323.964 71.139Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M318.277 71.139L319.688 69.7527L316.81 66.899L319.688 64.0469L318.277 62.6483L314 66.899L318.277 71.139Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='light-animation'
                    style={{ mixBlendMode: "screen" }}
                    d="M130.59 461.613C130.59 464.101 128.537 466.117 126.004 466.117C123.471 466.117 121.418 464.101 121.418 461.613C121.418 459.124 123.471 457.108 126.004 457.108C128.537 457.108 130.59 459.124 130.59 461.613Z"
                    fill="url(#paint5_radial_184_151)"
                />
                <path
                    className='arrow-aniamtion-svg'
                    d="M33.0399 480.62L32.0082 479.588L34.1313 477.483L32.0001 475.378L33.0411 474.347L36.2051 477.482L33.0399 480.62Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion-svg'
                    d="M37.2358 480.621L36.204 479.589L38.326 477.49L36.2029 475.385L37.2346 474.362L40.3906 477.49L37.2358 480.621Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion-svg'
                    d="M41.4316 480.62L40.3906 479.588L42.5218 477.483L40.3906 475.378L41.4316 474.347L44.5864 477.482L41.4316 480.62Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='light-animation'
                    style={{ mixBlendMode: "screen" }}
                    d="M120.272 461.613C120.272 464.101 117.963 466.117 115.113 466.117C112.263 466.117 109.955 464.101 109.955 461.613C109.955 459.124 112.263 457.108 115.113 457.108C117.963 457.108 120.272 459.124 120.272 461.613Z"
                    fill="url(#paint6_radial_184_151)"
                />
                <path
                    className='light-animation'
                    style={{ mixBlendMode: "screen" }}
                    d="M108.808 461.613C108.808 464.101 106.756 466.117 104.223 466.117C101.69 466.117 99.6372 464.101 99.6372 461.613C99.6372 459.124 101.69 457.108 104.223 457.108C106.756 457.108 108.808 459.124 108.808 461.613Z"
                    fill="url(#paint7_radial_184_151)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_184_151"
                        x1="212.556"
                        y1="104.61"
                        x2="212.556"
                        y2={1}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#002432" />
                        <stop offset={1} stopColor="#1C3F5E" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_184_151"
                        x1="212.373"
                        y1="447.174"
                        x2="212.373"
                        y2="491.19"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#002432" />
                        <stop offset={1} stopColor="#1C3F5E" />
                    </linearGradient>
                    <radialGradient
                        id="paint2_radial_184_151"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(324.902 461.05) scale(5.15874 5.06787)"
                    >
                        <stop offset="0.02" stopColor="#FFAAF5" />
                        <stop offset="0.06" stopColor="#50DBFF" />
                        <stop offset="0.22" stopColor="#50DBFF" />
                        <stop offset="0.23" stopColor="#4ACBEC" />
                        <stop offset="0.29" stopColor="#3AA1BB" />
                        <stop offset="0.35" stopColor="#2C7A8E" />
                        <stop offset="0.41" stopColor="#205968" />
                        <stop offset="0.48" stopColor="#163D47" />
                        <stop offset="0.55" stopColor="#0E272D" />
                        <stop offset="0.63" stopColor="#071519" />
                        <stop offset="0.72" stopColor="#03090A" />
                        <stop offset="0.82" stopColor="#000202" />
                        <stop offset={1} />
                    </radialGradient>
                    <radialGradient
                        id="paint3_radial_184_151"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(312.292 461.05) scale(5.15874 5.06787)"
                    >
                        <stop offset="0.02" stopColor="#AAFDFF" />
                        <stop offset="0.06" stopColor="#55A4FF" />
                        <stop offset="0.09" stopColor="#4095F9" />
                        <stop offset="0.14" stopColor="#1D7AF0" />
                        <stop offset="0.19" stopColor="#076AEB" />
                        <stop offset="0.22" stopColor="#0065E9" />
                        <stop offset="0.27" stopColor="#0050BA" />
                        <stop offset="0.33" stopColor="#003D8E" />
                        <stop offset="0.4" stopColor="#002D68" />
                        <stop offset="0.46" stopColor="#001F47" />
                        <stop offset="0.54" stopColor="#00132D" />
                        <stop offset="0.62" stopColor="#000A19" />
                        <stop offset="0.71" stopColor="#00040A" />
                        <stop offset="0.82" stopColor="#000102" />
                        <stop offset={1} />
                    </radialGradient>
                    <radialGradient
                        id="paint4_radial_184_151"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(300.255 461.05) scale(5.73193 5.06787)"
                    >
                        <stop offset="0.02" stopColor="#FFAAF5" />
                        <stop offset="0.06" stopColor="#50DBFF" />
                        <stop offset="0.22" stopColor="#50DBFF" />
                        <stop offset="0.23" stopColor="#4ACBEC" />
                        <stop offset="0.29" stopColor="#3AA1BB" />
                        <stop offset="0.35" stopColor="#2C7A8E" />
                        <stop offset="0.41" stopColor="#205968" />
                        <stop offset="0.48" stopColor="#163D47" />
                        <stop offset="0.55" stopColor="#0E272D" />
                        <stop offset="0.63" stopColor="#071519" />
                        <stop offset="0.72" stopColor="#03090A" />
                        <stop offset="0.82" stopColor="#000202" />
                        <stop offset={1} />
                    </radialGradient>
                    <radialGradient
                        id="paint5_radial_184_151"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(126.004 461.613) scale(4.58555 4.50477)"
                    >
                        <stop offset="0.02" stopColor="#FFAAF5" />
                        <stop offset="0.06" stopColor="#50DBFF" />
                        <stop offset="0.22" stopColor="#50DBFF" />
                        <stop offset="0.23" stopColor="#4ACBEC" />
                        <stop offset="0.29" stopColor="#3AA1BB" />
                        <stop offset="0.35" stopColor="#2C7A8E" />
                        <stop offset="0.41" stopColor="#205968" />
                        <stop offset="0.48" stopColor="#163D47" />
                        <stop offset="0.55" stopColor="#0E272D" />
                        <stop offset="0.63" stopColor="#071519" />
                        <stop offset="0.72" stopColor="#03090A" />
                        <stop offset="0.82" stopColor="#000202" />
                        <stop offset={1} />
                    </radialGradient>
                    <radialGradient
                        id="paint6_radial_184_151"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(115.113 461.613) scale(5.15874 4.50477)"
                    >
                        <stop offset="0.02" stopColor="#AAFDFF" />
                        <stop offset="0.06" stopColor="#55A4FF" />
                        <stop offset="0.09" stopColor="#4095F9" />
                        <stop offset="0.14" stopColor="#1D7AF0" />
                        <stop offset="0.19" stopColor="#076AEB" />
                        <stop offset="0.22" stopColor="#0065E9" />
                        <stop offset="0.27" stopColor="#0050BA" />
                        <stop offset="0.33" stopColor="#003D8E" />
                        <stop offset="0.4" stopColor="#002D68" />
                        <stop offset="0.46" stopColor="#001F47" />
                        <stop offset="0.54" stopColor="#00132D" />
                        <stop offset="0.62" stopColor="#000A19" />
                        <stop offset="0.71" stopColor="#00040A" />
                        <stop offset="0.82" stopColor="#000102" />
                        <stop offset={1} />
                    </radialGradient>
                    <radialGradient
                        id="paint7_radial_184_151"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(104.223 461.613) scale(4.58555 4.50477)"
                    >
                        <stop offset="0.02" stopColor="#FFAAF5" />
                        <stop offset="0.06" stopColor="#50DBFF" />
                        <stop offset="0.22" stopColor="#50DBFF" />
                        <stop offset="0.23" stopColor="#4ACBEC" />
                        <stop offset="0.29" stopColor="#3AA1BB" />
                        <stop offset="0.35" stopColor="#2C7A8E" />
                        <stop offset="0.41" stopColor="#205968" />
                        <stop offset="0.48" stopColor="#163D47" />
                        <stop offset="0.55" stopColor="#0E272D" />
                        <stop offset="0.63" stopColor="#071519" />
                        <stop offset="0.72" stopColor="#03090A" />
                        <stop offset="0.82" stopColor="#000202" />
                        <stop offset={1} />
                    </radialGradient>
                </defs>
            </svg>

        </div>
    )
}

export default Slale_Card_Frame
