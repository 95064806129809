import React from 'react'

const Header_Frame = () => {
    return (
        <>
            <div className='absolute header-frame-uper top-0 left-2/4 translate-x-2/4'>
            </div>
            <div className='absolute w-full  h-full top-0 left-0  z-10'>
                <div className='header_component_uper w-[110%] left-[-1%] top-[0%] md:w-[90%] md:left-[5%] md:top-[0%] lg:w-[80%] lg:left-[10%] lg:top-[0%] xl:w-[80%] xl:left-[10%] xl:top-[0%]'></div>
                <div className='header_component_center w-[72%] sm:w-2/4 sm:left-[25%] left-[14%] top-[0%] md:w-[40%] md:left-[30%] md:top-[0%] lg:w-[30%] lg:left-[35%] lg:top-[0%] xl:w-[28%] xl:left-[36.5%] xl:top-[0]'></div>
                <div className='header_component_border_inner bg-black left-2/4 sm:w-[0%] sm:top-[0%] sm:left-[5%] md:w-[0%] md:top-[0%] md:left-[5%] lg:w-[90%] lg:top-[0%] lg:left-[5%] xl:w-[90%] xl:left-[5%] xl:top-[0%]'></div>
                <div className='header_component_border_outer bg-black w-[93%] top-[45%] left-[3.5%] md:w-[80%] md:top-[50%] md:left-[10%] lg:w-[74%] lg:top-[47%] lg:left-[13%] xl:w-[74%] xl:left-[13%] xl:top-[47%]'></div>
                {/* <img className='absolute bottom-[31%] right-[25%] w-[150px]' src='images/arrow-frame.svg' alt='arrow-frame' /> */}
                {/* <img className='absolute bottom-[31%] left-[25%] w-[150px] rotate-y-[180deg]' src='images/arrow-frame.svg' alt='arrow-frame' /> */}
            </div>
            {/* <div className='header-Head absolute top-0 left-0 h-48 w-full'>
          <Header_head />
          <img src='images/hedd.svg' className='w-full h-full'/>
        </div> */}
            <div>
                <span className='balls hidden sm:block sm:absolute sm:bottom-[37%]  md:bottom-[34%] sm:left-[5%] md:left-[10%] lg:bottom-[36%]   w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:left-[15%] z-10'></span>
                <span className='balls hidden sm:block sm:absolute sm:bottom-[37%]  md:bottom-[34%] sm:left-[8%] md:left-[12%] lg:bottom-[36%] w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:left-[17%] z-10'></span>
                <span className='balls hidden sm:block sm:absolute sm:bottom-[37%]  md:bottom-[34%] sm:left-[11%] md:left-[14%] lg:bottom-[36%]   w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:left-[19%] z-10'></span>
            </div>
            <div>
                <span className='balls hidden sm:block sm:absolute sm:bottom-[37%] md:bottom-[34%] sm:right-[5%] md:right-[10%] lg:bottom-[36%] w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:right-[15%] z-10'></span>
                <span className='balls hidden sm:block sm:absolute sm:bottom-[37%] md:bottom-[34%] sm:right-[8%] md:right-[12%] lg:bottom-[36%]  w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:right-[17%] z-10'></span>
                <span className='balls hidden sm:block sm:absolute sm:bottom-[37%] md:bottom-[34%] sm:right-[11%] md:right-[14%] lg:bottom-[36%]  w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:right-[19%] z-10'></span>
            </div>
            <div>
                <img src="images/arrow.svg" alt="" className='absolute bottom-[34%] md:bottom-[33%] w-4 md:w-4 left-[5%] sm:left-[14%] md:left-[20%] lg:bottom-[35%] lg:left-[26%] lg:w-5 z-10 arrow-aniamtion' />
                <img src="images/arrow.svg" alt="" className='absolute bottom-[34%] md:bottom-[33%] w-4 md:w-4 left-[7%] sm:left-[16%] md:left-[22%] lg:bottom-[35%] lg:left-[27%] lg:w-5 z-10 arrow-aniamtion' />
                <img src="images/arrow.svg" alt="" className='absolute bottom-[34%] md:bottom-[33%] w-4 md:w-4 left-[9%] sm:left-[18%] md:left-[24%] lg:bottom-[35%] lg:left-[28%] lg:w-5 z-10 arrow-aniamtion' />
                <div>
                    <img src="images/arrow.svg" alt="" className='absolute bottom-[34%] md:bottom-[33%] w-4 md:w-4 right-[5%] sm:right-[14%] md:right-[20%] lg:bottom-[35%] lg:right-[26%] lg:w-5 z-10 arrow-animation-alter head' />
                    <img src="images/arrow.svg" alt="" className='absolute bottom-[34%] md:bottom-[33%] w-4 md:w-4 right-[7%] sm:right-[16%] md:right-[22%] lg:bottom-[35%] lg:right-[27%] lg:w-5 z-10 arrow-animation-alter head' />
                    <img src="images/arrow.svg" alt="" className='absolute bottom-[34%] md:bottom-[33%] w-4 md:w-4 right-[9%] sm:right-[18%] md:right-[24%] lg:bottom-[35%] lg:right-[28%] lg:w-5 z-10 arrow-animation-alter head' />
                </div>
            </div>
        </>
    )
}

export default Header_Frame
