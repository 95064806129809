import React from 'react'
import Sale_card_frame from './Sale_card_frame'

const SaleCard = (props) => {
    return (
        <div className="countinbox py-3 relative">
            <Sale_card_frame />
            <div className="flex justify-between items-center px-9 sm:px-7">
                <div className="logo-glow">
                    <img src={props.img} alt={props.img_alt} width={props.img_width} height={props.img_height} className="h-8 w-auto" />
                </div>
                <span className="text-sm lg:text-base  text-[#fff]  title-defi-font tittle-shadow">{props.tittle}</span>
            </div>
            {/* <h3>{localeFixed(486944+Number(totalocsold))}</h3> */}
            <p className="text-center text-[#50dbff] text-base md:text-lg mt-11 lg:mt-16 salestatics title-defi-font">{props.amount}</p>
        </div>
    )
}

export default SaleCard
